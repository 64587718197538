<template>
  <div class="form-payment">
    <div class="title">{{ $t('payment') }}</div>
    <div class="add-box mt-2">
      <div class="d-flex align-center add-box-title">
        <ion-icon slot="icon-only" :icon="cardOutline" class="text-primary" />
        <div class="d-flex align-center justify-space-between ml-2">
          <ion-text class="text-gray-900 font-style-toolbar-segment ml-1">{{
            $t('add_card_or_credit_card')
          }}</ion-text>
          <ion-button fill="clear" @click="handleOpenModalAddCard">
            <ion-icon slot="icon-only" :icon="addCircleOutline" class="text-primary" />
          </ion-button>
        </div>
      </div>
    </div>

    <div class="main-card">
      <div>
        <CardList
          :isOpenModalAddCard="isOpenModalAddCard"
          @handleCloseModalPayment="handleCloseModalPayment"
          :customerDetailsEdit="customerDetailsEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { alertController } from '@ionic/vue';
import { cardOutline, addCircleOutline } from 'ionicons/icons';
import CardList from '@/modules/b2b/views/account/Account/components/NewCardList.vue';

export default {
  components: {
    CardList
  },
  data() {
    return {
      isOpenModalAddCard: false,
      user: [],
      isShowNetBankCard: true
    };
  },
  inject: ['$storage'],
  props: ['customerDetailsEdit'],
  setup() {
    return {
      cardOutline,
      addCircleOutline
    };
  },
  async created() {
    this.user = await this.$storage.getUser();
    if (this.user.country.id !== 193) this.isShowNetBankCard = false; //if user myamar disbale nets bank card
  },
  methods: {
    async presentAlert() {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: 'Notice',
        message: 'Feature is updating.',
        buttons: [this.$t('OK')]
      });
      await alert.present();
    },
    handleOpenModalAddCard() {
      this.isOpenModalAddCard = true;
      setTimeout(() => {
        this.isOpenModalAddCard = false;
      }, 300);
    },
    handleCloseModalPayment() {
      this.$emit('handleCloseModal');
    }
  }
};
</script>

<style src="./styles/ModalPayment.scss" lang="scss" scoped></style>

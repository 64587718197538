<template>
  <div class="form-upload-image">
    <div class="title">{{ $t('newcustomer.upload_image') }}</div>
    <div class="title-description">{{ $t('Optional') }}</div>
    <ion-grid>
      <ion-row>
        <ion-col size="4" v-for="(data, index) in listImage" :key="index">
          <div class="form-image">
            <ion-img :src="data.image" style="width: 100px; height: 100px; border-radius: 20px" />
            <div class="icon" @click="handleRemoveImage(data.id)"><ion-icon :icon="closeOutline" /></div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-button class="form-upload" @click="triggerUpload">
            <ion-icon :icon="addCircleOutline" />
            <ion-text>{{ $t('newcustomer.upload_image') }}</ion-text>
          </ion-button>
        </ion-col>
        <ion-input
          placeholder=""
          type="file"
          accept="image/*"
          @ionInput="onPhotoChanged"
          ref="photoInput"
          v-model="photoInput"
          class="d-none"
        >
        </ion-input>
      </ion-row>

      <div class="form-btn mt-2">
        <ion-button mode="md" @click="handleSaveImage">{{ $t('save') }}</ion-button>
      </div>
    </ion-grid>
    <ion-loading
      mode="ios"
      :is-open="isOpenRefLoading"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      :duration="timeout"
      @didDismiss="setOpenLoading(false)"
    >
    </ion-loading>
  </div>
</template>

<script>
import { apolloClient } from '@/main';
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { addCustomerImage, updateCustomerImage } from '@/modules/b2b/services/graphql';
import { fileToBase64 } from '@/modules/b2b/services/libs/image';
import { getImage } from '@/utils/';
import { errorAlert } from '@/utils/alert';
import { addCircleOutline, closeOutline } from 'ionicons/icons';
import { ref } from 'vue';
export default {
  props: ['image', 'customer_id', 'imageAfterCrop'],

  setup() {
    const isOpenModalCropRef = ref(false);
    const setOpenModalCrop = (state) => (isOpenModalCropRef.value = state);
    const isOpenRefLoading = ref(false);
    const setOpenLoading = async (state) => (isOpenRefLoading.value = state);
    return {
      isOpenRefLoading,
      setOpenLoading,
      closeOutline,
      addCircleOutline,
      isOpenModalCropRef,
      setOpenModalCrop,
      errorAlert
    };
  },
  data() {
    return {
      listImage: [],
      newListImage: [],
      listDelete: []
    };
  },
  async mounted() {
    await this.handleGetImage();
  },
  watch: {
    imageAfterCrop() {
      this.handleGetImageAfterCrop();
    }
  },
  methods: {
    async handleGetImage() {
      for (const value of this.image) {
        this.listImage.push({
          id: value.id,
          customer_id: this.customer_id,
          image: await getImage(value.image, IMAGE_PATH.CUSTOMER),
          active: true
        });
      }
    },

    handleRemoveImage(id) {
      this.listImage = this.listImage.filter((item) => item.id !== id);
      this.newListImage = this.newListImage.filter((item) => item.id !== id);
      typeof id === 'string' ? '' : this.listDelete.push(id);
    },
    async handleSaveImage() {
      this.setOpenLoading(true);
      try {
        const listAddImage = [];
        for (const value of this.newListImage) {
          delete value.id;
          listAddImage.push(value);
        }

        await apolloClient.mutate({
          mutation: addCustomerImage,
          variables: {
            payload: listAddImage
          }
        });
        await apolloClient.query({
          query: updateCustomerImage,
          variables: {
            id: this.listDelete
          }
        });
        this.setOpenLoading(false);
        this.$emit('handleCloseModalUploadImage');
      } catch (e) {
        this.setOpenLoading(false);
        this.errorAlert(e.message);
      }
    },
    async triggerUpload() {
      const ionInputEle = this.$refs.photoInput.$el;
      const inputEle = await ionInputEle.getInputElement();
      inputEle.click();
    },
    async onPhotoChanged(event) {
      const files = event.target.querySelector('input').files;
      if (!files.length) {
        return;
      }
      if (!files[0].type.includes('image')) {
        return;
      }
      const src = await fileToBase64(files[0]);
      this.$emit('handleSendImageCrop', src);
    },
    handleGetImageAfterCrop() {
      this.newListImage.push({
        id: 'temp' + Date.now(),
        customer_id: this.customer_id,
        image: this.imageAfterCrop,
        active: true
      });
      this.listImage.push({
        id: 'temp' + Date.now(),
        customer_id: this.customer_id,
        image: this.imageAfterCrop,
        active: true
      });
    }
  }
};
</script>

<style src="./styles/ModalUploadImage.scss" lang="scss" scoped></style>
<style lang="scss" scoped>
.header {
  text-align: center;
  padding: 16px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  background: var(--ion-color-primary);
}
.crop-area {
  display: flex;
  justify-content: center;
  padding: 16px 16px 0px 16px;
}
.form-btn {
  display: flex;
  justify-content: flex-end;
  & > ion-button {
    height: 45px;
    --border-radius: 10px;
    text-transform: none;
  }
}
</style>

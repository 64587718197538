<template>
  <div>
    <div v-if="viewEntered">
      <div v-if="customerDetailsEdit?.name" class="branch-form">
        <p>{{ $t('newcustomer.main_brand') }}</p>
      </div>
      <!-- Company Name -->
      <div class="card">
        <ion-card>
          <div class="avatar">
            <div class="avatar-display">
              <ion-slides :options="slideOpts">
                <ion-slide v-for="(data, index) in listImage" :key="index">
                  <div v-if="!data.isDefaultImage" style="width: 100%; height: 224px">
                    <td-image
                      style="width: 100%; height: 224px"
                      :image="data.image"
                      :imagePath="IMAGE_PATH.PRODUCT"
                    ></td-image>
                  </div>
                  <div
                    v-if="data.isDefaultImage"
                    class="image-default"
                    :style="`background: ${data.theme.background}; color:${data.theme.color}`"
                  >
                    {{
                      getInitialName(
                        customerDetailsEdit?.name ? customerDetailsEdit?.name : customerDetailsEdit?.branch
                      )
                    }}
                  </div>
                </ion-slide>
              </ion-slides>
            </div>

            <ion-button class="avatar-edit" @click="$emit('handleOpenModalUploadImage')">
              <ion-icon :icon="createOutline" />
              {{ $t('newcustomer.edit_image') }}
            </ion-button>
          </div>
          <ion-card-header>
            <ion-card-subtitle>{{ $t('newcustomer.company_name') }}</ion-card-subtitle>
            <div class="title">
              <ion-card-title>{{
                customerDetailsEdit?.name ? customerDetailsEdit?.name : customerDetailsEdit?.branch
              }}</ion-card-title>
              <ion-img :src="halalUrl" v-if="customerDetailsEdit?.halal_products"></ion-img>
            </div>
          </ion-card-header>
          <ion-card-content>
            <ion-card-subtitle>{{ $t('newcustomer.profile') }}</ion-card-subtitle>
            <div class="profile-text">{{ customerDetailsEdit?.profile }}</div>
            <ion-card-subtitle>{{ $t('newcustomer.address') }}</ion-card-subtitle>
            <div class="profile-form" v-for="(data, index) in customerDetailsEdit?.addresses" :key="index">
              <div v-if="data.is_default" class="profile-text">{{ getAddressFormat(data) }}</div>
            </div>
            <div class="d-flex flex-row pt-3">
              <div class="w-50">
                <ion-text class="ion-text-uppercase" color="text-medium"
                  ><p class="fs-12 fw-bold">{{ $t('customerPage.account_number') }}</p></ion-text
                >
                <ion-text class="profile-text" color="tertiary"
                  ><p>{{ customerDetailsEdit?.account_number }}</p></ion-text
                >
              </div>
            </div>
            <ion-button
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="$emit('handleOpenModalBusiness')"
              >{{ $t('newcustomer.edit_business_information') }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
      <!-- Person In Charge -->
      <!-- Default Delivery Time -->
      <div class="card">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle class="delivery_time">{{
              $t('newcustomer.person_in_charge')
            }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div v-for="(person, index) in sortListShowPersonInCharge" :key="index">
              <div v-if="person" class="form-person">
                <ion-text v-if="mobilePhone === person.phone || mobilePhone === person.mobile"
                  >{{
                    `${person.first_name}${person.last_name ? ' ' + person.last_name : ''} `
                  }}(You)</ion-text
                >
                <ion-text v-else
                  >{{ `${person.first_name}${person.last_name ? ' ' + person.last_name : ''} ` }}<br />{{
                    person.phone ? `(+${getPhoneNumber(person.phone || person.mobile)})` : '-'
                  }}</ion-text
                >
                <ion-text :class="person.position === 'Key Contact' ? 'text-primary' : ''">{{
                  person.position || 'Sub-Buyer'
                }}</ion-text>
              </div>
            </div>
            <ion-button
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="$emit('handleOpenModalPersonInCharge')"
              >{{ $t('newcustomer.edit_person_in_charge') }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
      <!-- Default Delivery Time -->
      <div class="card">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle class="delivery_time">{{
              $t('newcustomer.default_delivery_time')
            }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div v-for="(workingDay, index) in filterWorkingDays" :key="index">
              <div class="form-delivery-time">
                <ion-text>{{ workingDay.day_name }}</ion-text>
                <ion-text>{{ displayWorkingTime(workingDay) }}</ion-text>
              </div>
            </div>
            <ion-button
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="$emit('handleOpenModalEditDeliveryTime')"
              >{{ $t('newcustomer.edit_delivery_time') }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
      <!-- Payment Card Details -->
      <div class="card" v-if="isShowPayment">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle class="delivery_time">{{
              $t('newcustomer.payment_card_details')
            }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div>
              <div v-if="cardDefault.length !== 0 && listNetsCard.length === 0" class="form-delivery-time">
                <ion-text>**** **** **** {{ cardDefault.last4 }} </ion-text>
                <ion-text
                  >Expires {{ cardDefault.exp_month }}/{{
                    cardDefault.exp_year.toString().slice(2, 4)
                  }}</ion-text
                >
              </div>
              <div v-if="listNetsCard.length > 0" class="form-delivery-time">
                <div class="d-flex">
                  <div class="">
                    <div class="font-style-toolbar-button-semibold text-gray-900">NETS Card</div>
                    <div class="ont-style-toolbar-segment text-gray-700">
                      *{{ listNetsCard[0].last_4_digits_fpan }}
                      {{ listNetsCard[0].mtoken_expiry_date.substr(2) }}/{{
                        listNetsCard[0].mtoken_expiry_date.substr(0, 2)
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ion-button
              v-if="isShowEditCard"
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="$emit('handleOpenModalPayment')"
              >{{
                isShowSetPayment
                  ? $t('newcustomer.set_payment_card_details')
                  : $t('newcustomer.edit_payment_card')
              }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
      <!-- Payment Card Cuisine -->
      <div class="card">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle class="delivery_time"> {{ $t('cuisine_preference') }}</ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <div>
              <div class="form-delivery-time">
                <ion-text>{{ caculateCuisineShow }}</ion-text>
              </div>
            </div>
            <ion-button
              mode="md"
              size="small"
              shape="round"
              fill="outline"
              @click="setOpenModalCuisine(true)"
              >{{ caculateCuisineShow.length > 0 ? $t('edit_cusine') : $t('add_cuisine') }}</ion-button
            >
          </ion-card-content>
        </ion-card>
      </div>
    </div>
  </div>
  <!-- Modal Cuisine -->
  <ion-modal :is-open="isOpenModalCuisine" backdropDismiss="false">
    <CuisinePreferences
      :isCheckCustomerDetailsPage="true"
      :cuisinesCustomerDetails="dataCuisine"
      :customerId="customerDetailsEdit?.id"
      :countryId="countryId"
      @later="setOpenModalCuisine(false)"
      @apply="handleSaveCuisine"
    />
  </ion-modal>
  <ion-loading
    mode="ios"
    :is-open="isOpenRefLoading"
    cssClass="my-custom-class"
    :message="`${$t('please_wait')}. . .`"
    @didDismiss="setOpenLoading(false)"
  >
  </ion-loading>
</template>

<script>
import halal from '@/assets/images/b2b/home/halal.svg';
import NetsCardImage from '@/assets/images/nets-brand.png';
import { apolloClient } from '@/main';
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { getDefaultCard, getListCustomerCard } from '@/modules/b2b/services/graphql';
import { formatTime, getAddressFormat, getDefaultCountryCode } from '@/modules/b2b/services/libs/helper';
import { GetCuisines } from '@/modules/sale/services/graphql';
import { handleRandomBackground } from '@/modules/shared/utils';
import { handleCaculateCuisineShow } from '@/modules/shared/utils/';
import { createOutline } from 'ionicons/icons';
import { defineAsyncComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('b2b/payment');

export default {
  props: ['customerDetailsEdit', 'isGetListCardWhenBackBtn', 'mobilePhone', 'countryId'],
  components: {
    CuisinePreferences: defineAsyncComponent(() =>
      import('@/modules/shared/views/cuisine-preferences/index.vue')
    )
  },
  emits: [
    'closeModal',
    'handleOpenModalBusiness',
    'handleOpenModalEditDeliveryTime',
    'handleOpenModalUploadImage',
    'handleOpenModalPersonInCharge',
    'handleOpenModalPayment',
    'handleSendListShowPersonInCharge'
  ],
  setup() {
    const slideOpts = {
      initialSlide: 0,
      speed: 400
    };
    const isOpenRefLoading = ref(false);
    const setOpenLoading = async (state) => (isOpenRefLoading.value = state);
    const dataCuisine = ref([]);
    //modal cuisine
    const isOpenModalCuisine = ref(false);
    const setOpenModalCuisine = (state) => (isOpenModalCuisine.value = state);
    return {
      isOpenRefLoading,
      setOpenLoading,
      slideOpts,
      getAddressFormat,
      halal,
      createOutline,
      NetsCardImage,
      dataCuisine,
      handleCaculateCuisineShow,
      isOpenModalCuisine,
      setOpenModalCuisine,
      IMAGE_PATH
    };
  },
  inject: ['$storage'],
  watch: {
    isGetListCardWhenBackBtn() {
      this.handleGetDefaultCard();
    }
  },
  computed: {
    ...mapGetters(['listNetsCard']),
    filterWorkingDays() {
      return (
        (this.customerDetailsEdit &&
          this.customerDetailsEdit.working_hours &&
          this.customerDetailsEdit.working_hours.filter((item) => item.active)) ||
        []
      );
    },
    sortListShowPersonInCharge() {
      const data = this.customerDetailsEdit.persons.concat(this.customerDetailsEdit.sub_buyer);
      const findKeyContact = data?.find((item) => item.position === 'Key Contact');
      if (findKeyContact) {
        const key = data.indexOf(findKeyContact);
        data.splice(key, 1);
        data.unshift(findKeyContact);
        return data;
      } else {
        return data;
      }
    },
    isShowPayment() {
      return false; // hide stripe payment
    },
    isShowEditCard() {
      const userCreated = new Date(this.user.date_created);
      const time = new Date('2022-03-26');
      return userCreated > time ? true : false;
    },
    isShowSetPayment() {
      return this.listNetsCard.length === 0 && this.listCardByCustomerId.length === 0;
    },
    caculateCuisineShow() {
      return handleCaculateCuisineShow(this.dataCuisine);
    }
  },
  async mounted() {
    await this.setOpenLoading(true);
    this.user = await this.$storage.getUser();
    this.halalUrl = halal;
    await Promise.all([this.handleGetCuisine(), this.handleGetImage(), this.handleGetDefaultCard()]);
    this.viewEntered = true;
    await this.setOpenLoading(false);
    this.$emit('handleSendListShowPersonInCharge', this.sortListShowPersonInCharge);
  },
  data() {
    return {
      isOpenModalBusinessInformation: false,
      isDefaultImage: false,
      theme: [],
      listCardByCustomerId: [],
      cardDefault: [],
      halalUrl: '',
      viewEntered: false,
      listImage: [],
      user: []
    };
  },

  methods: {
    displayWorkingTime({ open_hour, open_minute, close_hour, close_minute }) {
      return `${formatTime(open_hour)}:${formatTime(open_minute)} - ${formatTime(close_hour)}:${formatTime(
        close_minute
      )}`;
    },
    getPhoneNumber(params) {
      if (!params) return '';
      const phone =
        params.indexOf(' ') > -1
          ? params.split(' ')[1]
          : params.length > 8
          ? params.substring(2, params.length)
          : params;
      const countryCode =
        params.indexOf(' ') > -1
          ? params.split(' ')[0]
          : params.length > 8
          ? params.substring(0, 2)
          : getDefaultCountryCode();
      const phoneNumber = countryCode + ' ' + phone;
      return phoneNumber;
    },
    async handleGetDefaultCard() {
      try {
        const defaultCard = await apolloClient.query({
          query: getDefaultCard,
          variables: {
            customerId: this.customerDetailsEdit.id
          }
        });
        let listCustomerCard = await apolloClient.query({
          query: getListCustomerCard,
          variables: {
            customerId: this.customerDetailsEdit.id
          }
        });

        const defaultCardData = defaultCard.data.getDefaultCardByCustomer?.cardId;
        this.listCardByCustomerId = listCustomerCard.data.listCardByCustomerId.data;
        let findCardDefault = [];
        for (const value of this.listCardByCustomerId) {
          if (value.id === defaultCardData) {
            findCardDefault = value;
            break;
          }
        }
        if (this.listCardByCustomerId.length === 0) {
          this.cardDefault = findCardDefault;
        }

        if (findCardDefault.length !== 0) {
          this.cardDefault = findCardDefault;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async handleGetImage() {
      for (const value of this.customerDetailsEdit.customerImage) {
        this.listImage.push({
          isDefaultImage: !value.image ? true : false,
          theme: !value.image ? handleRandomBackground() : '',
          image: !value.image ? '' : value.image
        });
      }
      if (this.listImage.length === 0) {
        this.listImage.push({
          isDefaultImage: true,
          theme: handleRandomBackground()
        });
      }
    },
    getInitialName(name) {
      let initials = '';
      let names = name.split(' ');
      let nameCount = names.length > 2 ? 2 : names.length;
      for (let i = 0; i < nameCount; i++) {
        if (names[i]) {
          initials += names[i][0].toUpperCase();
        }
      }
      return initials;
    },
    async handleGetCuisine() {
      const { data } = await apolloClient.query({
        query: GetCuisines,
        variables: {
          customerId: this.customerDetailsEdit.id,
          countryId: this.countryId
        }
      });
      this.dataCuisine = data.getCuisines;
    },
    async handleSaveCuisine() {
      await this.handleGetCuisine();
      this.setOpenModalCuisine(false);
    }
  }
};
</script>

<style lang="scss" src="@/assets/css/newdesign.scss" scoped></style>
<style lang="scss" src="./styles/ModalCustomerDetails.scss" scoped></style>
